<template>
  <div class="main">
    <div class="top">
      <div style="float:left" @click="back"><img src="@/assets/return1.png" alt=""></div>
      <h5 class="title">《隐私政策》 正文</h5>
    </div>
    <van-pagination v-model="currentPage" style="margin-top:30px" :page-count="pageCount" mode="simple" />
    <pdf
      ref="pdf"
      style=""
      src="https://static.ainvestcn.com/privacyPollcy.pdf"
      :page="pageNum"
      @num-pages="pageCount = $event"
      @page-loaded="currentPage = $event"
    />

  </div>
</template>

<script>
import pdf from 'vue-pdf'
import { Pagination } from 'vant'
export default {
	components: {
		[Pagination.name]: Pagination,
		pdf
	},
	data() {
		return {
			pageNum: 1,
			currentPage: 0,
			pageCount: 0
		}
	},
	methods: {
		prePage() {
			let pgnum = this.pageNum
			pgnum = pgnum > 1 ? pgnum - 1 : this.pageTotalNum
			this.pageNum = pgnum
		},
		back() {
			this.$router.go(-1)
		}
	}

}
</script>

<style scoped>
p{
    text-indent: 2em;
    margin: 0.5rem 0;
    font-size: 1rem;
    line-height: 25px;
}
h1{
    font-size: 18px;
    font-weight: 700;
}
.main{
    padding: 2.0vw
}
.top{
    width: 96vw;
    padding: 2.0vw;
    position: fixed;
    top: 0;
    left: 0;
    background: #f0f3f6;
}
.title,.second{
   text-align: center
}
.second{
    margin-top: 5vh;
    font-weight: 700;
    font-size: 20px;
}
</style>>

